import * as classNames from 'classnames';
import * as React from 'react';
import { CloseIcon } from './CloseButtonIcons';
import { MODAL_CLOSE_BUTTON_TEST_ID } from './Modal.test.consts';

const styles = require('./CloseButton.less');

interface CloseIconProps {
  onClick: () => any;
  className?: string;
  isSmall?: boolean;
}

export const CloseButton: React.FC<CloseIconProps> = props => (
  <div
    className={classNames(styles.container, props.className, {
      [styles.isSmallIcon]: props.isSmall,
    })}
    onClick={props.onClick}
    data-testid={MODAL_CLOSE_BUTTON_TEST_ID}
  >
    <CloseIcon/>
  </div>
);
